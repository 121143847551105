/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/variables.scss';
@import 'assets/styles/mixins.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// form design
form {
    button {
        width: 100%;
    }
}

// material form fields design
.mat-form-field {
    width: 100%;
}

// content below nav
.content {
    min-height: 76vh;
    padding-top: $nav-height + 10px;
    @include center-content();
}

// reponsive tables global design
.tablecontainer {
    height: auto;
    max-height: 400px;
    overflow: auto;

    table {
        width: 100%;

        th {
            z-index: 1 !important;
        }

        td {
            a {
                color: black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .mat-header-cell, .mat-cell {
            padding-right: 10px;
        }
    }
}

.h1{
  font-size: 40px !important;
}

.loading {
  text-align: center;
  padding-top: 10px;
}

.success {
  text-align: center;
  color: $success;
  padding-top: 10px;
}

.error {
  text-align: center;
  color: $error;
  padding-top: 10px;
}

.successButton {
  color: white;
  background-color: #009417;
}

.firebase-emulator-warning {
  display: none;
}

.content {
  margin-top: 50px;
  align-items: inherit !important;
}
  
@media screen and (max-width:576px) {
  .h1{
    font-size: 30px !important;
  }

  .h6{
    font-size: 15px !important;
  }

  .mat-card {
    margin: 15px auto !important;
    max-width: 250px;
  }
}